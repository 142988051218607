import {
  collection,
  getDocs,
  query,
  limit,
  where,
  addDoc,
  orderBy,
  startAfter,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { db } from "../utils/firebase";
import Layout from "./Layout";
import AddClient from "./AddClient";

const clientsCollection = collection(db, "clients");
const LIMIT = 10;

function Clients({ handleSignOut }) {
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [prevSearch, setPrevSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchClients = async () => {
    setIsLoading(true);
    let clientsQuery = query(
      clientsCollection,
      orderBy("data.date", "desc"),
      limit(LIMIT)
    );

    // Apply filters based on search query
    if (search) {
      clientsQuery = query(
        clientsCollection,
        where("name", ">=", search),
        where("name", "<=", search + "\uf8ff"),
        orderBy("name"),
        limit(20)
      );
    } else if (lastVisible) {
      clientsQuery = query(
        clientsCollection,
        orderBy("data.date", "desc"),
        startAfter(lastVisible),
        limit(LIMIT)
      );
    }

    const documentSnapshots = await getDocs(clientsQuery);
    const newClients = documentSnapshots.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    if (search !== prevSearch && search === "") {
      // If the search was cleared, reset the state completely
      setClients(newClients);
      setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
    } else if (!search) {
      // Append new clients to existing list if not searching
      setClients((prev) => [...prev, ...newClients]);
      setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
    } else {
      // Replace clients if searching
      setClients(newClients);
    }
    setIsLoading(false);
  };

  const handleAddClient = async (newClient) => {
    try {
      const docRef = await addDoc(collection(db, "clients"), newClient);
      navigate(`/klijenti/${docRef.id}`);
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  useEffect(() => {
    if (search !== prevSearch) {
      setClients([]);
      setLastVisible(null);
      setPrevSearch(search);
    }
    fetchClients(); // Fetch initial data or upon significant search changes
  }, [search]); // Reacting to changes in search input

  return (
    <Layout>
      <div className="clientsPage">
        <div className="flex">
          <h1>Klijenti</h1>
          <Button
            color="success"
            variant="contained"
            onClick={() => setOpen(true)}
          >
            Dodaj
          </Button>
        </div>
        <Fab
          className="fab"
          color="primary"
          variant="extended"
          onClick={handleSignOut}
        >
          <LogoutIcon sx={{ mr: 1 }} />
          Izloguj se
        </Fab>
        <TextField
          label="Pretraga"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="clients">
          {isLoading && !clients.length ? (
            <CircularProgress className="spinner" color="secondary" />
          ) : (
            <>
              {clients.map((item, index) => (
                <div className="client" key={item.id}>
                  {index + 1}.{" "}
                  <Link className="clientLink" to={`/klijenti/${item.id}`}>
                    {item.name}
                  </Link>
                  <Divider />
                </div>
              ))}
            </>
          )}
        </div>
        {!search && clients.length > 0 && (
          <Button
            className="loadMoreBtn"
            onClick={fetchClients}
            disabled={!lastVisible}
            variant="outlined"
          >
            Prikaži još
          </Button>
        )}
      </div>
      <AddClient
        open={open}
        setOpen={setOpen}
        handleAddClient={handleAddClient}
      />
    </Layout>
  );
}

export default Clients;
